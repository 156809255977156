import React from "react";
import { css } from "@emotion/core";

import Layout from "../components/layout";

import { TitleHeader, wrapper } from "../components/defaults/styles/elements"
import Info from "../components/defaults/assets/svg/info.svg"
import PressFooter from "../components/pressfooter"
import { color } from "../components/defaults/styles/theme";

const seo = {
  frontmatter: {
    title: "Privacy Policy",
  },
}

const Partner = () => {
    return (
      <>
        <Layout seo={seo}>
          <TitleHeader
            title="Privacy Policy"
            subtitle="<p>Last updated: 2020-09-15T16:36+02 UTC</p>"
            icon={<Info />}
          />

          <div
            css={[
              css`
                background: ${color.main_light};
                padding: 4rem 0;
                margin-top: 2em;
              `,
            ]}
          >
            <section
              css={[
                wrapper,
                css`
                  margin-bottom: 5em;
                  p {
                    margin-bottom: 0;
                    font-size: 0.95em;
                    padding-bottom: 0.5em;
                  }
                  a,
                  p {
                    color: ${color.main_dark};
                  }
                  > div {
                    margin: 1.5em 0;
                  }
                `,
              ]}
            >
              <div
                css={css`
                  max-width: 48rem;
                `}
              >
                <p>
                  PLUG & PLAY GERMANY GMBH (hereinafter PLUG AND PLAY) is
                  committed to respecting the privacy of users and to the
                  protection and security of their personal data. Accordingly,
                  users are informed about the way in which PLUG AND PLAY
                  collects, processes and protects the personal data provided to
                  it through its online platforms.
                </p>
                <p>
                  Users should carefully read this privacy policy, which has
                  been drafted in a clear and simple manner, so as to be easy to
                  understand, and freely and voluntarily determine whether they
                  wish to provide their personal data to PLUG AND PLAY. Users
                  also guarantee that they are of legal age and that the
                  information provided is true, accurate, complete and up to
                  date and that they will be liable for any damages, direct or
                  indirect, that may arise from any breach of such obligation.
                </p>
                <p>
                  If data provided belong to a third party, users guarantee that
                  they have informed the third party of the aspects contained
                  herein and obtained their consent to provide the data to PLUG
                  AND PLAY for the purposes specified above.
                </p>
                <br />
                <ol>
                  <li>
                    <b>The Scope of Application and Responsibility</b>
                    <br />
                    This privacy policy applies to all personal data collected
                    by PLUG AND PLAY, through its online platforms. The
                    following company is joint data controller:
                    <br />
                    Daimler AG – Mercedesstraße 137, 70327, Stuttgart, Germany
                    <br />
                    These companies have jointly determined the objectives and
                    means of processing the user’s personal data and have signed
                    an agreement regulating the functions and responsibilities
                    that are required of them in compliance with applicable data
                    protection regulations. To contact PLUG AND PLAY or DAIMLER
                    for any questions regarding this privacy policy, you may do
                    so through the following channels:
                    <br />
                    Email <br />
                    privacy.ger@pnptc.com
                  </li>
                  <li>
                    <b>Purposes of the processing and legal basis</b>
                    <br />
                    PLUG AND PLAY will process user data, automatically, for the
                    following specific purposes: <br />
                    We collect Personal Data from you when you voluntarily
                    provide such information such as when you contact us with
                    inquiries, register for access to our Platform, send us your
                    data when applying for our programs, or use certain PLUG AND
                    PLAY Services. <br />
                    The legal basis for processing your data for the purposes of
                    joining PLUG AND PLAY Platform, and answering the inquiries,
                    is the performance of a contract to which the data subject
                    is party or in order to take steps at the request of the
                    data subject prior to entering into a contract; and
                    legitimate interests pursued by the controller. <br />
                    Processing for the purposes will be necessary for PLUG AND
                    PLAY to provide its services. Such processing shall always
                    be carried out with the prior consent of the user, except in
                    those cases in which there is a legal obligation for PLUG
                    AND PLAY, for which the data must be processed. <br />
                    The data subject is informed that his/her personal data need
                    to be processed by a third party, for the performance of a
                    contract to which the data subject is a party. <br />
                    Such processing shall always be carried out in compliance
                    with applicable regulations and when the user’s fundamental
                    rights and freedoms do not prevail over such legitimate
                    interests.
                  </li>
                  <li>
                    <b>Data communication</b> <br />
                    The user is informed that his or her personal data may not
                    be communicated to any third entities.
                  </li>
                  <li>
                    <b>Data processors</b> <br />
                    User data may be processed by a suitable service provider
                    contracted by PLUG AND PLAY. This service provider will only
                    process data for the purposes established by PLUG AND PLAY,
                    following its precise instructions and in strict compliance
                    with legal regulations on personal data protection,
                    information security and other applicable regulations.
                  </li>
                  <li>
                    <b>International data transfers</b> <br />
                    User data processing may not involve their disclosure to
                    third parties established in countries outside the territory
                    of the European Union. If anytime these personal data is
                    disclosed to any country outside de E.U., PLUG AND PLAY will
                    assure the user that those data processors have adequate or
                    appropriate safeguards to process the personal data whereby
                    data recipient organizations are recognized as ensuring an
                    adequate level of protection.
                  </li>
                  <li>
                    <b>Retention period</b> <br />
                    PLUG AND PLAY will keep the user’s personal data only for
                    the time needed to achieve the purposes for which they were
                    collected. We may retain your Personal Data as long as you
                    are registered to use our services. However, we may retain
                    Personal Data for an additional period as is permitted or
                    required under applicable laws. Even if we delete your
                    Personal Data it may persist on backup or archival media for
                    an additional period of time for legal, tax or regulatory
                    reasons or for legitimate and lawful business purposes.
                  </li>
                  <li>
                    <b>User Rights</b> <br />
                    Users may, at any time and free of charge, exercise their
                    rights of access, rectification or deletion, opposition,
                    limitation and portability of their data by means of
                    contacting us by email or at the address specified at the
                    top of this Privacy Policy, in the terms established in the
                    regulations in force. <br />
                    Users are informed that if they consider that PLUG AND PLAY
                    has infringed, or may have infringed, their rights under
                    applicable data protection regulations, they may submit a
                    complaint to the relevant Control Authority. <br />
                    <a
                      href="https://www.baden-wuerttemberg.datenschutz.de/
"
                    >
                      https://www.baden-wuerttemberg.datenschutz.de/
                    </a>
                  </li>
                  <li>
                    <b>Revocation of consent</b>
                    Users’ acceptance that their data may be processed or
                    transferred shall always be revocable, without retroactive
                    effect. To revoke such consent, users may contact PLUG AND
                    PLAY through the following channels: <br />
                    E-mail: <br /> privacy.ger@pnptc.com
                  </li>
                  <li>
                    <b>Required nature of the data requested</b> <br />
                    Data in the forms provided by PLUG AND PLAY through its
                    online platforms marked with an asterisk (*) are required to
                    comply with the contractual or legal purpose established.{" "}
                    <br />
                    Therefore, if the user does not provide them, PLUG AND PLAY
                    will not be able to process his or her request.
                  </li>
                  <li>
                    <b>Automated Decisions</b> <br />
                    PLUG AND PLAY does not take automated decisions achieved by
                    processing user data.
                  </li>
                  <li>
                    <b>Personal data protection measures</b> <br />
                    PLUG AND PLAY uses various appropriate technical and
                    organisational measures to protect users‘ personal data.
                  </li>
                </ol>
              </div>
            </section>
          </div>

          <PressFooter theme={color.main_light} />
        </Layout>
      </>
    )
}

export default Partner;